import { useMainStore } from "@/stores/index"
import debounce from "debounce"

export default defineNuxtRouteMiddleware((ctx, from) => {
  // if (process.server) return
  const mainStore = useMainStore()
  const bookmarkStore = useBookmarksStore()
  const bookmarkCookie = useCookie('savedBookmark')

  const { status } = useAuth()

  if (status.value === 'authenticated') {
    mainStore.setFirstVisit()
  }

  const {
    closeSearch,
    closeAuthForm,
    closeMenu,
    isAuthFormOpen,
    isMenuOpen,
    setMenuOpenToNull
  } = mainStore

  if (isMenuOpen) {
    closeMenu()
  }

  if (isMenuOpen === false) {
    setMenuOpenToNull()
  }

  closeSearch()

  if ((ctx.path.startsWith('/profile') && !from.path.startsWith('/profile')) && process.client) {
    sessionStorage.setItem('previousRoute', from.path)
  }

  if (ctx.path.startsWith('/profile/dashboard') && (status.value !== 'authenticated')) {
    return navigateTo('/')
  }

  if (bookmarkCookie.value) {
    debounce(() => {
      bookmarkStore.toggleBookmark(bookmarkCookie.value)
      bookmarkStore.resetSavedBookmark()
    }, 500)
  }

  if (from.path === '/oauth/callback') {
    mainStore.setAfterOAuth(true)
  } else {
    isAuthFormOpen && closeAuthForm()
  }

  closeSearch()

  if (from.path === '/oauth/callback') {
    mainStore.setAfterOAuth(true)
  } else {
    isAuthFormOpen && closeAuthForm()
  }

  if (mainStore.firstVisit && ['/', '/'].includes(ctx.path)) {
    return navigateTo('/welcome')
  }

  if (!mainStore.firstVisit && ctx.path === '/welcome') {
    if (ctx.redirectedFrom === undefined) {
      return navigateTo('/')
    }
    return abortNavigation()
  }

  // if (ctx.path === '/' && !mainStore.firstVisit) {
  //   return navigateTo('/categories')
  // }
})