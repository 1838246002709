import type { DocumentNode } from 'graphql';

const FORBIDDEN_ERROR_MESSAGE = 'Forbidden access'

export const useGraphQLQuery = <TResponse>(
  query: DocumentNode, 
  variables: Record<string, any> = {},
  noCache: boolean = false
): { fetch: (firstRequest?: boolean) => Promise<TResponse | any> } => {
  const { status } = useAuth()
  const nuxtApp = useNuxtApp()

  const onDone = (resolve: (value: any) => void) => (data: any) => {
    resolve(data)
  }
  
  const onError = (resolve: (value: any) => void, reject: (reason?: any) => void, firstRequest: boolean) => (error: any) => {
    //catch unauthorized error and refresh token
    if (
      status.value === 'authenticated'
      &&
      (error.message === FORBIDDEN_ERROR_MESSAGE || error.message.includes(FORBIDDEN_ERROR_MESSAGE))
      &&
      firstRequest
    ){
      nuxtApp.runWithContext(() => {
        useRefresh()
        .then(() => {
          fetch(false).then(resolve).catch(reject)
        })
        .catch(reject)
      })
      return
    }
    reject(error)
  }

  const fetch = (firstRequest = true) => {
    return new Promise<TResponse | any>((resolve, reject) => {
      const fetchPolicy = noCache ? 'no-cache' : 'cache-first';
      let res;
      if (query.definitions[0].operation === 'mutation') {
        res = useMutation<TResponse>(query) 
        res.onDone(({ data }) => {
          return onDone(resolve)(data)
        })
        res.onError(onError(resolve, reject, firstRequest))
        res.mutate(variables)
      } else {
        res = useQuery<TResponse>(query, variables, { fetchPolicy })
        if (res.result.value) {
          return onDone(resolve)(res.result.value) 
        }
        res.onResult(({ data }) => {
          return onDone(resolve)(data)
        })
        res.onError(onError(resolve, reject, firstRequest))
      }

      if (!res) {
        reject('Query is not defined')
        return
      }
    });
  }

  return { fetch };
}