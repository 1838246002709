export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=375, initial-scale=1"},{"hid":"description","name":"description","content":""},{"name":"format-detection","content":"telephone=no"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.ico"},{"rel":"preconnect","href":"https://fonts.googleapis.com"},{"rel":"preconnect","href":"https://fonts.gstatic.com","crossorigin":""},{"rel":"stylesheet","href":"https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400&display=swap"},{"rel":"stylesheet","href":"https://fonts.googleapis.com/css2?family=Cormorant:ital@1&display=swap"},{"rel":"stylesheet","href":"https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600&display=swap"}],"style":[],"script":[],"noscript":[],"title":"CremeBrulee","htmlAttrs":{"lang":"ru"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = true

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"