import { defineStore } from "pinia"
import debounce from "debounce";

interface BookmarksState {
  bookmarks: Bookmark[];
  isFetching: boolean;
  tempRemoved: string[];
}

interface BookmarksResponse {
  articles: {
    data: BookmarkResponse[]
  }
}

type BookmarkResponse = {
  id: string;
  attributes: {
    article: {
      data: {
        id: string
      }
    }
  }
}

type Bookmark = {
  id: string | number;
  articleId: string;
}

export const useBookmarksStore = defineStore('bookmarks', {
  state: (): BookmarksState => ({
    bookmarks: [],
    isFetching: false,
    tempRemoved: []
  }),
  getters: {
    savedBookmarkId() {
      const bookmarkCookie = useCookie('savedBookmark')
      return bookmarkCookie.value
    }
  },
  actions: {
    async fetchBookmarks() {
      this.isFetching = true

      const userStore = useUserStore()

      const { fetch } = useGraphQLQuery<BookmarksResponse>(gql`
        query ($userId: ID!) {
          userBookmarks(
            filters: { user: { id: { eq: $userId } } }
            pagination: { start: 0, limit: 1000 }
            sort: []
          ) {
            data {
              id
              attributes {
                article {
                  data {
                    id
                  }
                }
              }
            }
          }
        }`, { userId: userStore.id })

      const data = await fetch()

      if (data && data.userBookmarks) {
        this.bookmarks = data.userBookmarks.data.map((bookmark: BookmarkResponse) => {
          return {
            id: bookmark.id,
            articleId: bookmark.attributes?.article?.data?.id,
          }
        })

        //remove duplicates
        this.bookmarks = this.bookmarks.filter((bookmark, index) => {
          return this.bookmarks.findIndex(b => b.articleId === bookmark.articleId) === index
        })

        this.isFetching = false
      }
    },
    async createBookrmark(article: string) {
      const userStore = useUserStore()
      const mutation = gql`
        mutation ($user: ID!, $article: ID!) {
          createUserBookmark (data: { user: $user, article: $article }){
            data {
              id
              attributes {
                createdAt
              }
            }
          }
        }
      `
      
      const variables = { user: userStore.id, article: article }

      const { fetch } = useGraphQLQuery(mutation, variables)

      const newBookmark = await fetch()

      if (newBookmark && newBookmark.createUserBookmark) {
        this.bookmarks.push({
          id: newBookmark.createUserBookmark.data.id,
          articleId: article,
        })

        const articleStore = useArticlesStore()
        articleStore.addBookmark(article, newBookmark.createUserBookmark.data.id)
      }

      return newBookmark
    },
    async deleteBookmark(articleID: string, id: string) {
      const mutation = gql`
        mutation ($id: ID!) {
          deleteUserBookmark (id: $id){
            data {
              id
              attributes {
                updatedAt
              }
            }
          }
        }
      `
      
      const variables = { id: id }

      const { fetch } = useGraphQLQuery(mutation, variables)

      const deletedBookmark = await fetch()

      if (deletedBookmark && deletedBookmark.deleteUserBookmark) {
        this.tempRemoved.push(articleID)

        const articleStore = useArticlesStore()
        articleStore.removeBookmark(articleID)
      }

      return deletedBookmark
    },
    toggleBookmark(articleId: string) {
      const articleStore = useArticlesStore()

      const bookmarked = this.bookmarks.some(bookmark => bookmark.articleId === articleId)
      const bookmarkId = this.bookmarks.find(bookmark => {
        return bookmark.articleId === articleId
      })?.id

      if (bookmarked && !this.tempRemoved.find(id => id === articleId)) {
        articleStore.removeBookmark(articleId)

        this.deleteBookmark(articleId, bookmarkId).catch(() => {
          articleStore.addBookmark(articleId)
          console.error('bookmark is not deleted')
        })
      } else {
        articleStore.addBookmark(articleId)

        this.createBookrmark(articleId).catch(() => {
          articleStore.removeBookmark(articleId)
          console.error('bookmark is not added')
        })
      }
    },
    resetTempRemoved() {
      this.bookmarks = this.bookmarks.filter(bookmark => {
        return !this.tempRemoved.includes(bookmark.articleId)
      });
      this.tempRemoved = []
    },
    saveBookmark(id: string) {
      const bookmarkCookie = useCookie('savedBookmark')
      bookmarkCookie.value = id

      const mainStore = useMainStore()
      mainStore.toggleAuthForm()
    },
    resetSavedBookmark() {
      const bookmarkCookie = useCookie('savedBookmark')
      bookmarkCookie.value = null
    },
    addBookmarkAfterAuthorization(value: boolean) {
      if (value && this.savedBookmarkId) {
        debounce(() => {
          this.toggleBookmark(this.savedBookmarkId)
          this.resetSavedBookmark()
        }, 500)
      }
    },
  }
})